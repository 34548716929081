<template>
  <div class="card-custom" v-if="ready">
    <b-card class="mt-3">
      <b-row class="text-center">
        <b-col md="3" offset-md="3">
          <b-form-group label="Date de debut">
            <b-form-datepicker v-model="filters['date_s']"></b-form-datepicker>
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group label="Date de fin">
            <b-form-datepicker v-model="filters['date_e']"></b-form-datepicker>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="text-center mt-3">
        <b-col md="3" offset-md="4">
          <b-button  v-show="loaderSearch === false" variant="primary" @click="datatableInit()">
            Recherche
          </b-button>
          <b-button  v-show="loaderSearch === true" variant="primary">
            <i class="fas fa-spinner fa-spin"></i>
          </b-button>
          <b-button class="ms-2" variant="success" href="/#/admin/apporteur/nouveau" v-if="$store.api.user.role === 1"><i class="fas fa-file text-white"></i></b-button>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="mt-3">
      <div class="">
        <h4 class="card-title d-inline">Partenaire commission:</h4>
      </div>
      <b-table ref="datalist" stacked="md" striped hover :items="datatable.data" :fields="fields" @sort-changed="sortChanged" show-empty empty-text="Il n'y a aucun enregistrement à afficher">
        <template #cell(periode)="data">
          Du {{formatDate(data.item.debut)}} au {{formatDate(data.item.fin)}}
        </template>
        <template #cell(status_id)="data">
          <b-button size="sm" :variant="$store.api.getParam(params.SouscriptionFactureStatus, data.value).color">{{$store.api.getParam(params.SouscriptionFactureStatus, data.value).titre}}</b-button>
        </template>
        <template #cell(montant_ht)="data">
          {{data.value.toFixed(2)}}€
        </template>
        <template #cell(docs)="data">
          <div v-if="data.item.status_id > 1">
            <b-button v-if="data.item.status_id === 3 && data.value.preuve != null" size="sm" variant="success" v-b-tooltip.hover title="Preuve de virement">P</b-button>
            <b-button v-if="data.value.facture === null" size="sm" class="ms-2" variant="primary" v-b-tooltip.hover title="Facture du apporteur" @click="$refs.uploadDoc.show(); doc_id = data.item.id; doc_key = 'facture'">F</b-button>
            <b-button v-else size="sm" class="ms-2" variant="success" v-b-tooltip.hover title="Facture du apporteur" :href="data.value.facture" target="_blank">F</b-button>
          </div>
        </template>
      </b-table>
      <b-pagination align="center" v-model="datatable.currentPage" @change="changePage" :total-rows="datatable.totalRow" :per-page="datatable.limitPage" aria-controls="itemList"></b-pagination>
    </b-card>
  </div>
</template>
<script>
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
export default {
  name: "",
  computed:{
  },
  components: {
  },
  data: () => ({
    ready: false,
    loaderSearch:false,
    params:{
      CommissionType:[]
    },
    fields: [
      {
        key: '_id.raison_social',
        label: 'Partenaire',
        sortable:true,
      },
      {
        key: 'nb_souscription',
        label: "Nombre de souscription",
        sortable:false,
      },
      {
        key: 'montant_ht',
        label: "Montant a payer",
        sortable:false,
      }
    ],
    datatable:{
      currentPage: 1,
      limitPage: 20,
      totalRow: 0,
      data: [],
    },
    sort:{
      key: 'montant_ht',
      value: 'DESC'
    },
    filters:{
    },
    doc_key:null,
    doc_id:null
  }),
  methods: {
    datatableInit(){
      if(this.loaderSearch === true){
        return false;
      }
      var params = {
        filters: this.filters,
        sort: this.sort,
        limit: this.datatable.limitPage,
        page: this.datatable.currentPage
      }
      this.loaderSearch = true;
      this.$store.api.ajax('/apporteur/partenaire', params, (res) => {
        this.datatable = res;
        this.loaderSearch = false;
      });
    },
    sortChanged(e){
      this.sort = {
        field: e.sortBy,
        value: e.sortDesc === true ? "DESC" : "ASC"
      };
      this.datatable.data = [];
      this.datatableInit();
    },
    changePage(e){
      this.datatable.currentPage = e;
      this.datatableInit();
    },
  },
  beforeMount() {
    this.$store.api.ajax('/apporteur/params', null, (res) => {
      if(res.status === true){
        res.data.SouscriptionFactureStatus.forEach((item) => {
          item.label = item.titre;
        });
        this.params = res.data
        this.ready = true;
      }
    })
  },
  mounted() {
    this.datatableInit();
  },
}
</script>
<style>
</style>
